$lato: 'Lato', "Arial", sans-serif; // default 14px text
$oswald: 'Oswald', "Arial", sans-serif; // default headings
$roboto: 'Roboto', "Arial", sans-serif; // menu
$raleway: 'Raleway', "Arial", sans-serif; // big (display) headings
$montserrat: 'Montserrat', "Arial", sans-serif; // other

// Cool
$aqua:  #7FDBFF;
$blue:  #0074D9;
$navy:  #001F3F;
$teal:  #39CCCC;
$green: #2ECC40;
$olive: #3D9970;
$lime:  #01FF70;

// Warm
$yellow:  #FFDC00;
$orange:  #FF851B;
$red:     #FF4136;
$fuchsia: #F012BE;
$purple:  #B10DC9;
$maroon:  #85144B;

// Gray Scale
$white:  #fff;
$silver: #ddd;
$gray:   #aaa;
$grey:   #aaa;
$black:  #111;

// colors by google design
$red_alt:     #F44336;
$pink:        #E91E63;
$purple_alt:  #9C27B0;
$deep_purple: #673AB7;
$indigo:      #3F51B5;
$blue_alt:    #2196F3;
$light_blue:  #03A9F4;
$cyan:        #00BCD4;
$teal_alt:    #009688;
$green_alt:   #4CAF50;
$light_green: #8BC34A;
$lime_alt:    #CDDC39;
$yellow_alt:  #FFEB3B;
$amber:       #FFC107;
$orange_alt:  #FF9800;
$deep_orange: #FF5722;
$brown:       #795548;

$grey_alt:    #9E9E9E;
$gray_alt:    #9E9E9E;
$blue_grey:   #607D8B;

$theme-color-1: $teal;
$theme-color-2: $deep_orange;
$theme-color-3: $amber;
$theme-color-4: $green_alt;
$theme-color-5: $maroon;

$red_form: #a94442;
$green_form: #3c763d;
$orange_form: #8a6d3b;

$default-color:                 $blue;
$default-desaturate-color:      desaturate($default-color, 20%);
$default-lighten-color:         lighten($default-color, 5%);
$default-enlighten-color:       lighten($default-color, 15%);
$default-super-enlighten-color: lighten($default-color, 30%);
$default-darken-color:          darken($default-color, 5%);
$default-darkener-color:        darken($default-color, 15%);

$default-color-rgba:           rgba($default-color, 0.5);
$default-lighten-color-rgba:   rgba($default-lighten-color, 0.5);
$default-enlighten-color-rgba: rgba($default-enlighten-color, 0.5);
$default-darken-color-rgba:    rgba($default-darken-color, 0.5);
$default-darkener-color-rgba:  rgba($default-darkener-color, 0.5);

// dark
$dark-theme-bg: #303030;
$dark-theme-color: #bbb;

// dividers
$spacer-tiny-height:   3rem;
$spacer-small-height:  6rem;
$spacer-medium-height: 9rem;
$spacer-tall-height:   12rem;

// block height
$block-tiny-alt-height:   12rem;
$block-tiny-height:       15rem;
$block-small-alt-height:  18rem;
$block-small-height:      21rem;
$block-medium-alt-height: 24rem;
$block-medium-height:     27rem;
$block-tall-alt-height:   30rem;
$block-tall-height:       33rem;

// footer height
$footer-tiny-height:   9rem;
$footer-medium-height: 15rem;
$footer-tall-height:   21rem;

// footer social icon size
$social-tiny-icon-size:    1.5rem;
$social-small-icon-size:   2.25rem;
$social-smedium-icon-size: 3rem;
$social-medium-icon-size:  3.75rem;
$social-lmedium-icon-size: 4.5rem;
$social-large-icon-size:   5.25rem;
$social-huge-icon-size:    6rem;

// footer social icon font size
$social-tiny-font-size:    .95rem;
$social-small-font-size:   1.3rem;
$social-smedium-font-size: 1.7rem;
$social-medium-font-size:  2.2rem;
$social-lmedium-font-size: 2.5rem;
$social-large-font-size:   3rem;
$social-huge-font-size:    3.25rem;

// margins, paddings
$offset-tiny:    1rem;
$offset-small:   2rem;
$offset-smedium: 3rem;
$offset-medium:  4rem;
$offset-lmedium: 5rem;
$offset-large:   6rem;
$offset-huge:    7rem;

//images
$countdown-block-bg: "/img/clock";
$calendar-block-bg:  "/img/event/big-2";
$fw-heading-bg:      "/img/landscape/6";
$fw-heading-bg-dark: "/img/landscape/7";
$portfolio-bg:       "/img/portfolio-bg-light";
$portfolio-bg-dark:  "/img/portfolio-bg-dark";
$vertical-img-1-bg:  "/img/landscape/1";
$vertical-img-2-bg:  "/img/landscape/2";
$vertical-img-3-bg:  "/img/landscape/3";
$vertical-img-4-bg:  "/img/landscape/4";
$horizontal-img-bg:  "/img/portfolio/23";
$event-img-bg:       "/img/event/big-2";
$pricing-table-bg:   "/img/landscape/5";
$social-images-bg:   "/img/landscape/4";

$counter-1: "/img/counter/1.jpg";
$counter-2: "/img/counter/2.jpg";
$counter-3: "/img/counter/3.jpg";
$counter-4: "/img/counter/4.jpg";
$counter-parallax: "/img/landscape/forest";

$image-overlay-block-bg: "/img/5";

$full-screen-bg:   "/img/new_girl_w";
$full-screen-bg-2: "/img/welcome/light/4";
$full-screen-bg-3: "/img/welcome/light/2";
$full-screen-bg-4: "/img/welcome/light/3";
// $full-screen-bg-5: "/img/welcome/light/1";
$full-screen-bg-6: "/img/welcome/light/5";
$full-screen-bg-7: "/img/girl";

$full-screen-bg-dark:   "/img/new_girl_b";
$full-screen-bg-dark-2: "/img/welcome/dark/4";
$full-screen-bg-dark-3: "/img/welcome/dark/girl";
$full-screen-bg-dark-4: "/img/welcome/dark/boat";
// $full-screen-bg-dark-5: "/img/welcome/dark/3";
$full-screen-bg-dark-6: "/img/welcome/dark/1";
$full-screen-bg-dark-7: "/img/welcome/dark/2";
$full-screen-intro: "/img/device/hands6";

$full-screen-auth-bg: "/img/blurry";
$footer-bg: "/img/2";
$footer-special: "/img/landscape/special-2";
$feedback-bg: "/img/cup";
$feedback-bg-dark: "/img/cup2";

$image-half:   "/img/corporate/ih-1";
$image-half-2: "/img/corporate/team5";

$elements-img:       "/img/welcome/elements-1";
$elements-img-dark:  "/img/welcome/elements-2";
$ui-img:             "/img/welcome/ui-1";
$ui-img-dark:        "/img/welcome/ui-2";
$shop-img:           "/img/welcome/shop-1";
$shop-img-dark:      "/img/welcome/shop-2";
$portfolio-img:      "/img/welcome/portfolio-1";
$portfolio-img-dark: "/img/welcome/portfolio-2";
$blog-img:           "/img/welcome/blog-1";
$blog-img-dark:      "/img/welcome/blog-2";
$features-img:       "/img/welcome/features-1";
$features-img-dark:  "/img/welcome/features-2";
$pages-img:          "/img/welcome/pages-1";
$pages-img-dark:     "/img/welcome/pages-2";

$parallax-1: "/img/landscape/b-1";
$parallax-2: "/img/landscape/b-2";
$parallax-3: "/img/landscape/b-3";
$parallax-4: "/img/landscape/b-7";
$parallax-5: "/img/5";
$parallax-6: "/img/welcome/pages-2";
$parallax-7: "/img/landscape/b-4";
$parallax-8: "/img/landscape/b-5";
$parallax-9: "/img/landscape/b-6";

$about-me-bg-2: "/img/device/5";


$attention-box-bg:            "/img/app/overlays/light-4";
$attention-box-bg-dark:       "/img/app/overlays/dark-4";
$attention-box-parallax:      "/img/roof";
$attention-box-parallax-dark: "/img/landscape/5";

$coming-soon-timer: "/img/2";
$coming-soon-timer: "/img/2";
$mosaic-img-1: "/img/corporate/team6";
$mosaic-img-2: "/img/device/mono3";
$mosaic-img-3: "/img/device/hands8";
$clients-grid-bg: "/img/landscape/1";
$carousel-bg: "/img/landscape/2";

$i404-fuzz-img: "/img/app/fuzz.gif";
$i404-color-bars-bg: "/img/app/color-bars.png";

$scroll-up-image-bg: "/img/app/scrollup.png";

$masonry-1:  230px;
$masonry-2:  180px;
$masonry-3:  480px;
$masonry-4:  200px;
$masonry-5:  230px;
$masonry-6:  310px;
$masonry-7:  185px;
$masonry-8:  215px;
$masonry-9:  245px;
$masonry-10: 350px;
$masonry-11: 500px;
$masonry-12: 400px;
$masonry-13: 170px;
$masonry-14: 200px;
$masonry-15: 180px;
$masonry-16: 240px;
$masonry-17: 185px;
$masonry-18: 450px;
$masonry-19: 368px;
$masonry-20: 250px;

$screen-tn:      321px;
$screen-tn-max:  479px;

$screen-xs:      480px;
$screen-xs-max:  767px;

$screen-sm:      768px;
$screen-sm-max:  991px;

$screen-md:      992px;
$screen-md-max: 1199px;

$screen-lg:     1200px;


@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
  &::placeholder               {@content}
}
